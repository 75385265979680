import { getPlace } from "../utils/getPlace";
import { getSession } from "../utils/getSession";

export const createEvent = async (floordId?: string) => {
  const place = getPlace();
  const sessionId = getSession();

  const response = await fetch("https://api.proximi.fi/core/events", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_PROXIMI_TOKEN!}`,
    },
    body: JSON.stringify({
      data: {
        floor_id: floordId,
        place_id: place?.id,
        location: place?.location,
        visitor_id: sessionId,
        type: "native",
        department_id: null,
        geofence_id: null,
      },
      event: "enter",
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to create visitor");
  }

  return response.json();
};
