// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawerRouter_container__nhgF4 {
  display: flex;
  justify-content: center;
}

.DrawerRouter_arrowButton__S8hgU {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.DrawerRouter_box__fq0HC {
  max-width: 680px;
  position: absolute;
  width: 100%;
  padding: 1.5rem 1.5rem .8rem;
  bottom: 0;
  background: #fff;
  border-radius: 12px 12px 0 0;
  box-sizing: border-box;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, .25);
}

.DrawerRouter_content__VuNby {
  display: flex;
  gap: .5rem;
  flex-direction: column;
  transition: all .3s;
  height: auto;
}

.DrawerRouter_content__VuNby.DrawerRouter_collapse__nfopi {
  transition: all .3s;
  overflow: hidden;
  height: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DrawerRouter/DrawerRouter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,4BAA4B;EAC5B,SAAS;EACT,gBAAgB;EAChB,4BAA4B;EAC5B,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n}\n\n.arrowButton {\n  position: absolute;\n  top: -15px;\n  left: 50%;\n  transform: translate(-50%, 0);\n}\n\n.box {\n  max-width: 680px;\n  position: absolute;\n  width: 100%;\n  padding: 1.5rem 1.5rem .8rem;\n  bottom: 0;\n  background: #fff;\n  border-radius: 12px 12px 0 0;\n  box-sizing: border-box;\n  box-shadow: 2px 2px 12px rgba(0, 0, 0, .25);\n}\n\n.content {\n  display: flex;\n  gap: .5rem;\n  flex-direction: column;\n  transition: all .3s;\n  height: auto;\n}\n\n.content.collapse {\n  transition: all .3s;\n  overflow: hidden;\n  height: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DrawerRouter_container__nhgF4`,
	"arrowButton": `DrawerRouter_arrowButton__S8hgU`,
	"box": `DrawerRouter_box__fq0HC`,
	"content": `DrawerRouter_content__VuNby`,
	"collapse": `DrawerRouter_collapse__nfopi`
};
export default ___CSS_LOADER_EXPORT___;
