import s from "./Brandmark.module.css";

const Brandmark = () => {
  return <>
    <img
      className={s.geniumSolution}
      src="/assets/genium-solutions.svg"
      alt="Genium"
      width={76}
      height={20}
    />
  </>
}

export default Brandmark;