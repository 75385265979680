// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Landing_header__CDdXf {
  text-align: center;
  color: white;
  padding: 80px 0;
}

.Landing_logo__oUlXx {
  margin-bottom: 18px;
}

.Landing_stackButtons__CsQTg {
  display: flex;
  flex-direction: column;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/Landing.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".header {\n  text-align: center;\n  color: white;\n  padding: 80px 0;\n}\n\n.logo {\n  margin-bottom: 18px;\n}\n\n.stackButtons {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Landing_header__CDdXf`,
	"logo": `Landing_logo__oUlXx`,
	"stackButtons": `Landing_stackButtons__CsQTg`
};
export default ___CSS_LOADER_EXPORT___;
