// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Maps_contentOverlayTop__R5lSP {
  position: absolute;
  margin-top: .5rem;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
}

.Maps_contentMap__\\+ZNxl {
  overflow: auto;
  height: 100%;
}

.Maps_loadingOverlay__7sLSs {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  z-index: 99;
  gap: 1rem;
}

.Maps_buttonsContainer__MWbOM {
  display: flex;
  flex-direction: row;
  gap: .6rem;
}

.Maps_button__QFPyL {
  width: 100%;
}

.Maps_extraInformation__3wvKn {
  font-size: 16px;
  min-height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Maps/Maps.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,2BAA2B;EAC3B,SAAS;AACX;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,gCAAgC;EAChC,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".contentOverlayTop {\n  position: absolute;\n  margin-top: .5rem;\n  text-align: center;\n  transform: translateX(-50%);\n  left: 50%;\n}\n\n.contentMap {\n  overflow: auto;\n  height: 100%;\n}\n\n.loadingOverlay {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  display: flex;\n  flex-direction: column;\n  transform: translate(-50%, -50%);\n  z-index: 99;\n  gap: 1rem;\n}\n\n.buttonsContainer {\n  display: flex;\n  flex-direction: row;\n  gap: .6rem;\n}\n\n.button {\n  width: 100%;\n}\n\n.extraInformation {\n  font-size: 16px;\n  min-height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentOverlayTop": `Maps_contentOverlayTop__R5lSP`,
	"contentMap": `Maps_contentMap__+ZNxl`,
	"loadingOverlay": `Maps_loadingOverlay__7sLSs`,
	"buttonsContainer": `Maps_buttonsContainer__MWbOM`,
	"button": `Maps_button__QFPyL`,
	"extraInformation": `Maps_extraInformation__3wvKn`
};
export default ___CSS_LOADER_EXPORT___;
