// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonCollapse_buttonCircle__KG438 {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
}

.ButtonCollapse_buttonCircle__KG438:hover {
  transition: all .3s;
  background: #eee;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.ButtonCollapse_active__cL1K9 {
  transition: all .3s;
  transform: rotate(0deg);
}

.ButtonCollapse_inactive__1Wuk3 {
  transform: rotate(-180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonCollapse/ButtonCollapse.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".buttonCircle {\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 100px;\n  background: #FFF;\n  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);\n}\n\n.buttonCircle:hover {\n  transition: all .3s;\n  background: #eee;\n  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.6);\n  cursor: pointer;\n}\n\n.active {\n  transition: all .3s;\n  transform: rotate(0deg);\n}\n\n.inactive {\n  transform: rotate(-180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonCircle": `ButtonCollapse_buttonCircle__KG438`,
	"active": `ButtonCollapse_active__cL1K9`,
	"inactive": `ButtonCollapse_inactive__1Wuk3`
};
export default ___CSS_LOADER_EXPORT___;
