// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Brandmark_geniumSolution__CKjI6 {
  position: absolute;
  right: 18px;
  top: 10px;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Brandmark/Brandmark.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,oBAAoB;AACtB","sourcesContent":[".geniumSolution {\n  position: absolute;\n  right: 18px;\n  top: 10px;\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"geniumSolution": `Brandmark_geniumSolution__CKjI6`
};
export default ___CSS_LOADER_EXPORT___;
