// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_wrapper__KsDQJ {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.Layout_backgroundImage__czeXG {
  width: 100%;
  height: 669px;
  position: absolute;
  top: 0;
  z-index: -1;
  background:
    linear-gradient(0deg, rgba(20, 5, 8, 0.75) 0%, rgba(20, 5, 8, 0.05) 100%),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: cover;
}

.Layout_backgroundOverlay__CTWkp {
  z-index: -1;
  width: 100%;
  height: 254px;
  position: absolute;
  top: 416px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)
}

.Layout_container__dVyML {
  max-width: 380px;
  padding: 0 24px;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX;;mEAE8D;EAC9D,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV;AACF;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".wrapper {\n  height: 100%;\n  overflow-y: auto;\n  position: relative;\n}\n\n.backgroundImage {\n  width: 100%;\n  height: 669px;\n  position: absolute;\n  top: 0;\n  z-index: -1;\n  background:\n    linear-gradient(0deg, rgba(20, 5, 8, 0.75) 0%, rgba(20, 5, 8, 0.05) 100%),\n    url(\"/public/assets/background.jpg\") no-repeat center center;\n  background-size: cover;\n}\n\n.backgroundOverlay {\n  z-index: -1;\n  width: 100%;\n  height: 254px;\n  position: absolute;\n  top: 416px;\n  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)\n}\n\n.container {\n  max-width: 380px;\n  padding: 0 24px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Layout_wrapper__KsDQJ`,
	"backgroundImage": `Layout_backgroundImage__czeXG`,
	"backgroundOverlay": `Layout_backgroundOverlay__CTWkp`,
	"container": `Layout_container__dVyML`
};
export default ___CSS_LOADER_EXPORT___;
