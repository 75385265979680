// src/session.js
const PLACE_KEY = "gn_place_session";

type Place = {
  id: string;
  location: {
    lat: number;
    lng: number;
  };
  name: string;
};

export const createPlace = (place: Place) => {
  localStorage.setItem(PLACE_KEY, JSON.stringify(place));
  return place;
};

export const getPlace = (): Place | null => {
  const place = localStorage.getItem(PLACE_KEY);
  if (place) {
    return JSON.parse(place);
  }
  return null;
};
