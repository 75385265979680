import { useCallback, useEffect, useRef, useState } from "react";
import s from "./Maps.module.css";
import Proximiio from "proximiio-js-library";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { FloorModel } from "proximiio-js-library/lib/models/floor";
import { Map } from "proximiio-js-library/lib/components/map/main";
import { Route } from "../../models/Route.type";
import Feature from "proximiio-js-library/lib/models/feature";
import Title from "antd/es/typography/Title";
import {
  AccessibleRouteSegmented,
  Brandmark,
  DrawerRouter,
  SelectFeature,
  SelectFloor,
} from "../../components";
import { getMetadata } from "../../utils/getMetadata";
import InteractionLogger from "proximiio-js-library/lib/components/logger/interaction";
import { createPlace } from "../../utils/getPlace";
import { createEvent } from "../../api/createEvent";
import { trackSession } from "../../analytics/proximiAnalytics";

const Maps = () => {
  const map = useRef<Map>();
  const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
  const [searchParams] = useSearchParams();
  const [floorActive, setFloorActive] = useState<FloorModel | null>(null);
  const [floors, setFloors] = useState<FloorModel[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [fromPoiId, setFromPoiId] = useState<string | null>(null);
  const [toPoiId, setToPoiId] = useState<string | null>(null);
  const [routes, setRoutes] = useState<Route | null>(null);
  const [pathPartActive, setPathPartActive] = useState<number>(1);
  const [isAccessibleRoute, setIsAccessibleRoute] = useState<boolean>(false);

  useEffect(() => {
    Proximiio.Auth.loginWithToken(process.env.REACT_APP_PROXIMI_TOKEN!)
      .then((res) => {
        trackSession();

        map.current = new Proximiio.Map({
          allowNewFeatureModal: false,
          zoomIntoPlace: false,
          mapboxOptions: {
            zoom: 21,
            maxZoom: 24,
            minZoom: 20,
            bearing: 0,
            pitch: 10,
            center: [-37.70093701758144, 27.48537590632302],
          },
          isKiosk: false,
          kioskSettings: {
            coordinates: [0, 0],
            level: 0,
          },
          initPolygons: true,
          polygonsOptions: {
            defaultPolygonColor: "#d8d9bd",
            hoverPolygonColor: "#bfc096",
            selectedPolygonColor: "#bfc096",
            defaultLabelColor: "#000",
            defaultPolygonHeight: 0.6,
            hoverPolygonHeight: 0.6,
            selectedPolygonHeight: 0.6,
            opacity: 0.9,
            removeOriginalPolygonsLayer: false,
            textFont: ["Open Sans Regular"],
          },
          showLevelDirectionIcon: true,
          routeAnimation: {
            enabled: true,
            type: "point",
            durationMultiplier: 20,
          },
          useTimerangeData: true,
        });

        map.current?.getMapReadyListener().subscribe((ready: boolean) => {
          try {
            /**
             * Save the current place to the local storage
             * This is used to track the user's current location
             */
            const { place } = map.current?.getMapState();
            createPlace(place);

            const poiIdParam = searchParams.get("poi");
            if (poiIdParam) {
              map.current?.centerToFeature(poiIdParam);
              setFromPoiId(poiIdParam);
            }
          } catch (err) {
            console.error(err);
          }
        });

        map.current.getFloorSelectListener().subscribe((res: any) => {
          const mapState = (map.current as any)?.state;
          const featuresData = mapState.features?.features
            .filter((f: Feature) => f.properties.type === "poi")
            .filter((f: Feature) => f.id)
            .map((f: Feature) => ({
              ...f,
              properties: {
                ...f.properties,
                floorName: mapState.floors?.find(
                  (fm: FloorModel) => fm.level === f.properties.level
                )?.name,
              },
            }));

          createEvent(mapState.floor?.id);
          setFeatures(featuresSorterByLevelAndAlphabet(featuresData));
          setFloorActive(mapState.floor);
          setFloors(mapState.floors);
        });

        map.current.getRouteFoundListener().subscribe((res: any) => {
          const pathParts = [];
          for (const key in res.route) {
            if (Object.prototype.hasOwnProperty.call(res.route, key)) {
              const element = res.route[key];
              pathParts.push(element);
            }
          }

          setPathPartActive(1);
          setRoutes({ ...res, pathParts });
        });

        map.current.getPolygonClickListener().subscribe((polygon: Feature) => {
          map.current?.centerToFeature(polygon.id);
          setFromPoiId(polygon.id);

          // Events analytics
          const data = getMetadata();
          new InteractionLogger({
            interactionType: "mapclick",
            targetElementId: polygon.id,
            targetElementTitle: polygon?.properties.title ?? "",
            targetElementType: "feature",
            targetElementAmenityCategory: polygon?.type ?? "",
            ...data,
          });
        });

        map.current.getNavStepSetListener().subscribe((step) => {
          setPathPartActive(step + 1);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const featuresSorterByLevelAndAlphabet = (ft: Feature[]) => {
    let results: Feature[] = [];

    const groups = (ft as any).reduce((r: any, a: any) => {
      r[a.properties.level] = [...(r[a.properties.level] || []), a];
      return r;
    }, {});

    for (const key in groups) {
      if (Object.prototype.hasOwnProperty.call(groups, key)) {
        const element = (groups[key] as Feature[]).sort(function (a, b) {
          return a.properties.title.localeCompare(b.properties.title);
        });
        results = [...results, ...element];
      }
    }
    return results;
  };

  const onFloorSelected = (floorId: string) => {
    map.current?.setFloorById(floorId);
  };

  const onFromPoiSelected = (poiId: string) => {
    map.current?.centerToFeature(poiId);
    setFromPoiId(poiId);
  };

  const onToPoiSelected = (poiId: string) => {
    map.current?.centerToFeature(poiId);
    setToPoiId(poiId);
  };

  const onResetRoute = () => {
    setRoutes(null);
    map.current?.cancelRoute();
  };

  const onPrevStep = () => {
    map.current?.setNavStep("previous");
  };

  const onNextStep = () => {
    map.current?.setNavStep("next");
  };

  const findRouteByIds = () => {
    map.current?.findRouteByIds(
      toPoiId as string,
      fromPoiId as string,
      isAccessibleRoute,
      {
        avoidHills: false,
        avoidElevators: !isAccessibleRoute,
        avoidRamps: !isAccessibleRoute,
        avoidEscalators: isAccessibleRoute,
        avoidStaircases: isAccessibleRoute,
        avoidBarriers: isAccessibleRoute,
        avoidNarrowPaths: isAccessibleRoute,
        avoidRevolvingDoors: isAccessibleRoute,
        avoidTicketGates: isAccessibleRoute,
      }
    );
  };

  const handleGoRoutes = () => {
    if (fromPoiId && toPoiId && fromPoiId !== toPoiId) {
      findRouteByIds();
    }
  };

  const centerToCoordinates = useCallback(
    (poiId: string) => {
      if (features.length) {
        const poi = features.find((f) => f.id === poiId);
        if (poi) {
          map.current?.centerToCoordinates(
            poi?.geometry.coordinates[1],
            poi?.geometry.coordinates[0],
            22
          );
        }
      }
    },
    [features]
  );

  useEffect(() => {
    if (fromPoiId && toPoiId) {
      findRouteByIds();
    }
  }, [isAccessibleRoute]);

  useEffect(() => {
    if (fromPoiId) {
      centerToCoordinates(fromPoiId);
    }
  }, [fromPoiId, centerToCoordinates]);

  useEffect(() => {
    if (toPoiId) {
      centerToCoordinates(toPoiId);
    }
  }, [toPoiId, centerToCoordinates]);

  return (
    <>
      {map.current && (
        <>
          <div className={s.contentOverlayTop}>
            <SelectFloor
              floorActive={floorActive}
              floors={floors}
              onChange={onFloorSelected}
            />
          </div>

          {routes ? (
            <DrawerRouter
              headerRef={
                <>
                  {routes.pathParts?.length > 1 && (
                    <AccessibleRouteSegmented
                      value={isAccessibleRoute ? "elevator" : "stairs"}
                      onChange={(evt) => {
                        setIsAccessibleRoute(evt === "elevator");
                      }}
                    />
                  )}

                  <div>
                    <Title level={4} style={{ margin: "0" }}>
                      Route to
                    </Title>
                    <Title level={4} style={{ marginTop: "0" }}>
                      [L{routes.TBTNav?.destination?.properties.level}]{" "}
                      {routes.TBTNav?.destination?.properties.title}
                    </Title>
                  </div>

                  {routes.pathParts?.length > 1 && (
                    <div className={s.extraInformation}>
                      Path part: {pathPartActive}/{routes.pathParts?.length}
                    </div>
                  )}
                </>
              }
              bodyRef={
                <>
                  {routes.pathParts?.length > 1 && (
                    <>
                      <div className={s.buttonsContainer}>
                        <Button
                          onClick={onPrevStep}
                          size="large"
                          className={s.button}
                          disabled={pathPartActive <= 1}
                        >
                          Prev
                        </Button>
                        <Button
                          onClick={onNextStep}
                          size="large"
                          type="primary"
                          className={s.button}
                          disabled={pathPartActive >= routes.pathParts?.length}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </>
              }
              footerRef={
                <>
                  <Button
                    onClick={onResetRoute}
                    className={s.button}
                    size="large"
                  >
                    Cancel
                  </Button>
                </>
              }
            />
          ) : (
            <DrawerRouter
              headerRef={
                <>
                  <Title level={4} style={{ marginBottom: "0" }}>
                    Route
                  </Title>
                  <SelectFeature
                    placeholder="Pick the start Point of Interest"
                    featureIdActive={fromPoiId}
                    features={features}
                    onChange={onFromPoiSelected}
                  />
                  <SelectFeature
                    placeholder="Where are you going?"
                    featureIdActive={toPoiId}
                    features={features}
                    onChange={onToPoiSelected}
                  />
                </>
              }
              footerRef={
                <Button
                  onClick={handleGoRoutes}
                  size="large"
                  type="primary"
                  disabled={!fromPoiId || !toPoiId}
                >
                  Go
                </Button>
              }
            />
          )}
        </>
      )}

      <div className={s.contentMap}>
        <Brandmark />
        <div id="proximiioMap" style={{ height: "99%" }} />
      </div>

      {!map.current && (
        <div className={s.loadingOverlay}>
          <Spin indicator={antIcon} />
          <span>Loading...</span>
        </div>
      )}
    </>
  );
};

export default Maps;
