import platform from "platform";

export const createVisitor = async () => {
  const response = await fetch("https://api.proximi.fi/core/visitors", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_PROXIMI_TOKEN!}`,
    },
    body: JSON.stringify({
      data: {
        type: "user",
        localeData: "en_EN",
        user: "",
        brand: platform.product || "Unknown",
        version: platform.version || "Unknown",
        manufacturer: platform.manufacturer || "Unknown",
        model: platform.name || "Unknown",
      },
      locale: "en_EN",
      platform: platform.product ? "Mobile" : "Desktop",
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to create visitor");
  }

  return response.json();
};
