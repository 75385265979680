// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ButtonCard_button__CFZPo {
  padding: 0;
  border: none;
  border-radius: 8px;
  color: #fff;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  text-decoration: none;
  transition: all .3s;
}

.ButtonCard_button__CFZPo:hover {
  opacity: .9;
}

.ButtonCard_dark__u\\+7rp {
  background: #202939;
}

.ButtonCard_accent__d7DHc {
  background: #A90029;
}

.ButtonCard_buttonImage__fb8Gr {
  width: 94px;
  height: 94px;
  object-fit: cover;
}

.ButtonCard_buttonContent__9aIfW {
  text-align: left;
  padding: 0 16px;
}

.ButtonCard_description__hS6c1 {
  font-size: 13px;
  line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonCard/ButtonCard.module.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["\n.button {\n  padding: 0;\n  border: none;\n  border-radius: 8px;\n  color: #fff;\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n  align-items: center;\n  text-decoration: none;\n  transition: all .3s;\n}\n\n.button:hover {\n  opacity: .9;\n}\n\n.dark {\n  background: #202939;\n}\n\n.accent {\n  background: #A90029;\n}\n\n.buttonImage {\n  width: 94px;\n  height: 94px;\n  object-fit: cover;\n}\n\n.buttonContent {\n  text-align: left;\n  padding: 0 16px;\n}\n\n.description {\n  font-size: 13px;\n  line-height: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonCard_button__CFZPo`,
	"dark": `ButtonCard_dark__u+7rp`,
	"accent": `ButtonCard_accent__d7DHc`,
	"buttonImage": `ButtonCard_buttonImage__fb8Gr`,
	"buttonContent": `ButtonCard_buttonContent__9aIfW`,
	"description": `ButtonCard_description__hS6c1`
};
export default ___CSS_LOADER_EXPORT___;
