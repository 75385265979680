const SESSION_KEY = "user_session";

export const createSession = (sessionId: string) => {
  localStorage.setItem(SESSION_KEY, sessionId);
  return sessionId;
};

export const getSession = () => {
  const sessionId = localStorage.getItem(SESSION_KEY);
  return sessionId || '';
};
