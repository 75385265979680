import { useState } from "react";
import { ArrowIcon } from "../Icons";
import s from "./ButtonCollapse.module.css";

type ButtonCollapseProps = {
  className?: string;
  isCollapse?: boolean;
}

const ButtonCollapse = ({ isCollapse }: ButtonCollapseProps) => {
  const [toggle, setToggle] = useState<boolean>(true);
  
  const toggleButton = () => {
    setToggle(prev => !prev);
  }

  return <>
    <div
      onClick={toggleButton}
      className={[
        toggle ? s.active: s.inactive,
        s.buttonCircle,
        s.className,
      ].join(" ")}
    >
      <ArrowIcon />
    </div>
  </>
}

export default ButtonCollapse;