import { useEffect, useState } from "react";
import { Select } from "antd";
import { GenericOption } from "../../models/Generic.type";
import { FloorModel } from "proximiio-js-library/lib/models/floor";

type SelectFloorProps = {
  floorActive: FloorModel | null;
  floors: FloorModel[];
  onChange: (floorId: string) => void;
}

const SelectFloor = ({ floorActive, floors, onChange }: SelectFloorProps) => {
  const [options, setOptions] = useState<GenericOption[]>([])

  useEffect(() => {
    if (floors?.length) {
      setOptions(floors.map(f => ({
        value: f.id,
        label: f.name
      })))
    }
  }, [floors]);

  return <>
    <Select
      value={floorActive?.id}
      style={{ width: 120 }}
      onChange={onChange}
      options={options}
      size="small"
      className="selectCustom"
    />
  </>
}

export default SelectFloor;