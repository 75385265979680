import { useEffect, useRef, useState } from "react";
import { LoadingOverlay } from "../../components";

const iframeStyle = `
  .brand,
  .downloads,
  .mdc-list-group__subheader,
  .mat-toolbar>span:last-child,
  .mat-mdc-nav-list app-menu-list-item:nth-child(2),
  .mat-mdc-nav-list app-menu-list-item:nth-child(3),
  .mat-mdc-nav-list app-menu-list-item:nth-child(4),
  .mat-mdc-nav-list app-menu-list-item:nth-child(5),
  .mat-mdc-nav-list app-menu-list-item:nth-child(6),
  .mat-mdc-nav-list app-menu-list-item:nth-child(7),
  .mat-mdc-nav-list app-menu-list-item:nth-child(8),
  .mat-mdc-nav-list app-menu-list-item:nth-child(10),
  .mat-mdc-nav-list app-menu-list-item:nth-child(11),
  .mat-mdc-nav-list app-menu-list-item:nth-child(12),
  .mat-mdc-menu-content div button:nth-child(1)
  {
    display: none !important;
  }
`;

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const onIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow?.document;
        const style = document.createElement("style");
        style.innerHTML = iframeStyle;
        iframeDocument?.head.appendChild(style);
        setLoading(false);
      };
    }
  };

  useEffect(() => {
    onIframeLoad();
  }, []);

  return (
    <>
      <LoadingOverlay spinning={loading} />
      <iframe
        ref={iframeRef}
        src="/iframe-analytics"
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="99%"
        height="99%"
      />
    </>
  );
};

export default Analytics;
