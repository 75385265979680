import platform from "platform";
import { getSession } from "./getSession";

export const getMetadata = () => {
  return {
    metadata: {
      osName: platform?.os?.family || "Unknown",
      browserName: platform.name || "Unknown",
      mobileModel: platform.product || "Unknown",
      mobileVendor: platform.manufacturer || "Unknown",
      deviceType: platform.product ? "Mobile" : "Desktop",
    },
    language: navigator.language || "Unknown",
    session: getSession(),
  };
};
