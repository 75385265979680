// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccessibleRouteSegmented_segmentOptions__YDcpi {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/AccessibleRouteSegmented/AccessibleRouteSegmented.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,QAAQ;AACV","sourcesContent":[".segmentOptions {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 6px;\n  gap: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmentOptions": `AccessibleRouteSegmented_segmentOptions__YDcpi`
};
export default ___CSS_LOADER_EXPORT___;
