import { Select } from "antd";
import InteractionLogger from "proximiio-js-library/lib/components/logger/interaction";
import Feature from "proximiio-js-library/lib/models/feature";
import { getMetadata } from "../../utils/getMetadata";

type SelectFeatureProps = {
  placeholder: string;
  featureIdActive: string | null;
  features: Feature[];
  onChange: (featureId: string) => void;
};

const SelectFeature = ({
  placeholder,
  featureIdActive,
  features,
  onChange,
}: SelectFeatureProps) => {
  const getOptionsMap = (features: Feature[]) => {
    return features.map((f) => ({
      value: f.id,
      label: `[${f.properties.floorName}] ${f.properties.title}`,
    }));
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (featureId: string) => {
    onChange(featureId);
    const feature = features.find((f) => f.id === featureId);
    const data = getMetadata();
    new InteractionLogger({
      interactionType: "select",
      targetElementId: featureId,
      targetElementTitle: feature?.properties.title ?? "",
      targetElementType: "feature",
      targetElementAmenityCategory: feature?.type ?? "",
      ...data,
    });
  };

  return (
    <>
      <Select
        showSearch
        value={featureIdActive}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={handleChange}
        filterOption={filterOption}
        size="large"
        options={getOptionsMap(features)}
      />
    </>
  );
};

export default SelectFeature;
