import React from "react";
import s from "./Landing.module.css";
import { ButtonCard, Layout } from "../../components";
import { useSearchParams } from "react-router-dom";

const Landing = () => {
  const [searchParams] = useSearchParams();
  const poiIdParam = searchParams.get("poi");

  return <>
    <Layout>
      <header className={s.header}>
        <img
          className={s.logo}
          src="/assets/logo.png"
          alt=""
          width={96}
          height={96}
        />
        <p>Welcome to</p>
        <h1>Cherokee County Clerk of Courts</h1>
      </header>

      <div className={s.stackButtons}>
        <ButtonCard
          variant="accent"
          title="Open Wayfinder"
          description="Explore our interactive map for office locations!"
          image="/assets/image-button-2.png"
          link={`maps${poiIdParam ? `?poi=${poiIdParam}` : ""}`}
        />

        <ButtonCard
          variant="dark"
          title="Patty Baker"
          description="Clerk of Superior, State, and Magistrate Courts"
          image="/assets/image-button-1.png"
          link="https://www.cherokeecourtclerk.com"
          target="_blank"
        />
      </div>
    </Layout>
  </>
}

export default Landing;