import { ReactNode, useState } from "react";
import s from "./DrawerRouter.module.css";
import ButtonCollapse from "../ButtonCollapse/ButtonCollapse";

type DrawerRouterProps = {
  headerRef?: ReactNode;
  bodyRef?: ReactNode;
  footerRef?: ReactNode;
}

const DrawerRouter = ({ headerRef, bodyRef, footerRef }: DrawerRouterProps) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  const toggleButton = () => {
    setIsCollapse(prev => !prev);
  }

  return <>
    <div className={s.container}>
      <div className={s.box}>
        <span className={s.arrowButton} onClick={toggleButton}>
          <ButtonCollapse />
        </span>
        <div
          className={[
            isCollapse ? s.collapse : "",
            s.content,
          ].join(" ")}
        >
          {headerRef}
        </div>

        <div className={s.content}>
          {bodyRef}
        </div>

        <div
          style={{ marginTop: isCollapse ? "0" : ".5rem" }}
          className={[
            isCollapse ? s.collapse : "",
            s.content,
          ].join(" ")}
        >
          {footerRef}
        </div>
      </div>
    </div>
  </>
}

export default DrawerRouter;