import s from "./ButtonCard.module.css";
import { Link } from "react-router-dom";

type ButtonCardProps = {
  variant: "dark" | "accent";
  title: string;
  description: string;
  image: string;
  link: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}

const ButtonCard = ({ variant = "dark", title, description, image, link, target = "_self" }: ButtonCardProps) => {
  return <>
    <Link
      className={[
        s.button,
        s[variant]
      ].join(" ")}
      to={link}
      target={target}
    >
      <div className={s.buttonImage}>
        <img width={94} src={image} alt="" />
      </div>
      <div className={s.buttonContent}>
        <h3>{title}</h3>
        <span className={s.description}>{description}</span>
      </div>
    </Link>
  </>
}

export default ButtonCard;